import React, { useState } from 'react'
import { Input, Menu } from 'antd'
import { Header } from 'antd/es/layout/layout'
const { Search } = Input

const Headers = () => {
  const [current, setCurrent] = useState(window.location.pathname)

  const handleClick = (e) => {
    setCurrent(e.key)
    window.location.replace(e.key)
  }
  const onSearch = (text) => {
    window.find(text)
  }

  return (
    <div className="header">
      <Search
        className="search"
        placeholder="请输入搜索的内容"
        onSearch={onSearch}
        enterButton
      />

      <Header>
        <Menu
          className="nav"
          onClick={handleClick}
          selectedKeys={[current]}
          mode="horizontal"
          theme="dark"
        >
          <Menu.Item key="/">
            <span className="nav-font">减重与代谢</span>
          </Menu.Item>
          <Menu.Item key="/im">
            <span className="nav-font">结直肠癌</span>
          </Menu.Item>
          <Menu.Item key="/waist">
            <span className="nav-font">腰痛 伴/不伴根性 症状</span>
          </Menu.Item>
          <Menu.Item key="/tinnitus">
            <span className="nav-font">耳鸣</span>
          </Menu.Item>
          <Menu.Item key="/ear">
            <span className="nav-font">听力受损眩晕</span>
          </Menu.Item>
          <Menu.Item key="/ig">
            <span className="nav-font">IgG4相关疾病</span>
          </Menu.Item>
        </Menu>
      </Header>
    </div>
  )
}
export default Headers
