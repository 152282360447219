import React, { lazy, Suspense, useState } from 'react'
import { Route, Router, Switch, useHistory } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import { Affix, Layout, Menu, Space } from 'antd'
const history = createBrowserHistory({ basename: '/' })

const BodyPage = lazy(() => import('../page/body/bodyPage'))

const minHeight = { minHeight: '100vh' }
const { Header, Content, Footer } = Layout

import './router.less'
import Headers from './header'

const Index = () => {
  const linkToWindow = (url) => {
    window.open(url)
  }
  return (
    <Router history={history}>
      <Switch>
        <Suspense fallback={<div>Loading...</div>}>
          <Layout className="Layout" style={minHeight}>
            <Affix offsetTop={0}>
              <Headers />
            </Affix>

            <Content className="site-layout" style={{ padding: '0' }}>
              <Route exact path="/" component={BodyPage} />
              <Route exact path="/im" component={BodyPage} />
              <Route exact path="/waist" component={BodyPage} />
              <Route exact path="/ear" component={BodyPage} />
              <Route exact path="/tinnitus" component={BodyPage} />
              <Route exact path="/ig" component={BodyPage} />
            </Content>

            <Footer style={{ textAlign: 'center' }}>
              <div className="footer">
                <span>影像学检查临床适用性评价</span>
              </div>

              <div className="footer-row">
                <Space className="footer-p">
                  外部链接：
                  <span onClick={() => linkToWindow('http://www.c-mbs.com')}>
                    大中华减重官网
                  </span>
                  |
                  <span
                    onClick={() => linkToWindow('https://database.c-mbs.com')}
                  >
                    大中华减重数据库
                  </span>
                  |
                  <span
                    onClick={() => linkToWindow('https://sgleak.c-mbs.com')}
                  >
                    胃漏数据登记平台
                  </span>
                  |
                  <span
                    onClick={() =>
                      linkToWindow('https://www.base-nafld.com/Login')
                    }
                  >
                    NAFLD数据登记平台
                  </span>
                  |{' '}
                  <span
                    onClick={() => linkToWindow('https://www.order-trial.com')}
                  >
                    ORDER数据登记平台
                  </span>
                </Space>

                <span>京ICP备17044059号</span>
              </div>
            </Footer>
          </Layout>
        </Suspense>
      </Switch>
    </Router>
  )
}

export default Index
